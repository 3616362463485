
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import OverlayPanel from "primevue/overlaypanel";
import {
  faChevronDown,
  faSortAmountDown,
  faSortAmountUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Tree from "primevue/tree";
import { ShopCategory, ShopProduct } from "@/types/shop";
import SvgIcon from "@/components/SvgIcon.vue";
import { formatPrice } from "@/helpers/shop.helper";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import ClaritySelect from "@/components/clarity/ClaritySelect.vue";
import { SortType } from "@/types/main";
import { Dictionary } from "vue-router/types/router";
import * as lodash from "lodash";

export default Vue.extend({
  name: "ProductsView",
  components: {
    ClaritySelect,
    ClarityInput,
    SvgIcon,
    ProductThumbnail,
    OverlayPanel,
    FontAwesomeIcon,
    Tree,
  },
  metaInfo(): { titleTemplate: string; meta: any } {
    const title = [];
    if (this.selectedCategory?.title) {
      title.push(this.selectedCategory?.title);
    } else {
      title.push("Produkty");
    }
    if (this.isNew) {
      title.push("Nowości");
    }
    if (this.isPremium) {
      title.push("Premium");
    }
    if (this.isSale) {
      title.push("Wyprzedaż");
    }
    if (this.isSet) {
      title.push("Zestawy");
    }
    title.push(this.companyName);
    return {
      titleTemplate: title.join(" | "),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: (title.join(" | ") + " | " + this.description).substring(
            0,
            160
          ),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title.join(" | "),
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: title.join(" | ") + " | " + +this.description,
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content:
            (this.selectedCategory?.title
              ? this.selectedCategory?.title
              : "Produkty") +
            " | " +
            +this.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${window.location.href}`,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${window.location.origin}/logo2.png`,
        },
      ],
    };
  },

  data: function () {
    return {
      sortBy: "updated",
      sortType: SortType.ASC,
      typeItems: [
        { value: "title", label: "nazwy" },
        { value: "updated", label: "daty dodania" },
        { value: "price", label: "ceny" },
      ],
      faChevronDown,
      selectedCategoryId: null as { [key: string]: boolean } | null,
      minPrice: undefined as number | undefined,
      maxPrice: undefined as number | undefined,
      search: "",
      isNew: false,
      isSale: false,
      isSet: false,
      isPremium: false,
      faSortAmountUpAlt,
      faSortAmountDown,
      SortType,
    };
  },

  computed: {
    ...mapGetters(ModuleNames.shop, {
      visibleShopProducts: "visibleShopProducts",
      shopCategories: "shopCategories",
      flattenShopCategories: "flattenShopCategories",
      productsIsLoading: "productsIsLoading",
    }),
    ...mapState({ companyName: "companyName", description: "description" }),

    filteredShopProducts(): Array<ShopProduct> {
      if (!this.visibleShopProducts) return [];
      return this.visibleShopProducts.filter((product: ShopProduct) => {
        const selectedCategoryId = this.selectedCategoryId
          ? Object.keys(
              this.selectedCategoryId as { [key: string]: boolean }
            )?.[0]
          : undefined;
        const categoryFilter = selectedCategoryId
          ? product.categories.includes(selectedCategoryId)
          : true;
        const minFilter = this.minPrice
          ? product.thumbnail.price >= this.minPrice
          : true;
        const maxFilter = this.maxPrice
          ? product.thumbnail.price <= this.maxPrice
          : true;
        const searchFilter = this.search
          ? product.title?.toLowerCase()?.includes(this.search.toLowerCase()) ||
            product.tags?.find((t) =>
              t.toLowerCase()?.includes(this.search.toLowerCase())
            )
          : true;
        const isNew = this.isNew ? product.isNew : true;
        const isSale = this.isSale ? product.isSale : true;
        const isSet = this.isSet ? product.isSet : true;
        const isPremium = this.isPremium ? product.isPremium : true;

        return (
          categoryFilter &&
          minFilter &&
          maxFilter &&
          searchFilter &&
          isNew &&
          isSale &&
          isSet &&
          isPremium
        );
      });
    },
    sortedShopProducts(): Array<ShopProduct> {
      if (!this.filteredShopProducts) return [];

      const items = [...this.filteredShopProducts]?.sort((a, b) => {
        if (this.sortBy === "title") {
          return a.title
            ?.toLowerCase()
            ?.trim()
            .localeCompare(b.title?.toLowerCase()?.trim());
        }
        if (this.sortBy === "price") {
          return a.thumbnail.price - b.thumbnail.price;
        }
        return (a.createdAt || "") < (b.createdAt || "") ? 0 : -1;
      });
      if (this.sortType === SortType.DESC) return items.reverse();
      return items;
    },

    selectedCategory(): ShopCategory | undefined {
      if (!this.selectedCategoryId) return undefined;
      return this.flattenShopCategories.find((c: ShopCategory) =>
        Object.keys(
          this.selectedCategoryId as { [key: string]: boolean }
        ).includes(c._id)
      );
    },
    selectedCategoryBreadcrumbs(): Array<{ title: string; value: string }> {
      const paths = this.selectedCategory?.path?.split("/");
      const idsPaths = this.selectedCategory?.idsPath?.split("/");
      if (paths)
        return paths.map((p, i) => ({
          title: p,
          value: idsPaths && idsPaths[i] ? idsPaths[i] : "",
        }));
      return [];
    },
    categories(): Array<{
      key: string;
      label: string;
      data: any;
      children: any;
    }> {
      const mapCategories = (
        categories?: Array<ShopCategory>
      ): Array<{
        key: string;
        label: string;
        data: any;
        children: any;
      }> => {
        if (!categories) return [];

        return categories.map((c: ShopCategory) => ({
          key: c._id,
          label: c.title,
          data: c,
          children: mapCategories(c.subcategories),
        }));
      };
      return this.shopCategories?.map((c: ShopCategory) => ({
        key: c._id,
        label: c.title,
        data: c,
        children: mapCategories(c.subcategories),
      }));
    },
  },
  methods: {
    formatPrice,
    toggle(event: Event, ref: string): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.$refs?.[ref]?.toggle(event);
    },
    updateQuery(): void {
      const query = {
        nowosci: this.isNew ? "tak" : undefined,
        wyprzedaz: this.isSale ? "tak" : undefined,
        zestawy: this.isSet ? "tak" : undefined,
        premium: this.isPremium ? "tak" : undefined,
        kategoria: this.selectedCategoryId
          ? Object.keys(
              this.selectedCategoryId as { [key: string]: boolean }
            )[0]
          : undefined,
        sortujWg: this.sortBy !== "price" ? this.sortBy : undefined,
        sortujTyp: this.sortType !== SortType.ASC ? this.sortType : undefined,
        minCena: this.minPrice ? this.minPrice.toString() : undefined,
        maxCena: this.maxPrice ? this.maxPrice.toString() : undefined,
        szukaj: this.search ? this.search : undefined,
      };
      const modifyQueryParams = this.modifyQueryParams(query);
      if (!lodash.isEqual(this.$route.query, modifyQueryParams))
        this.$router.push({
          query: modifyQueryParams,
        });
    },
    modifyQueryParams(
      query: Dictionary<
        string | number | Array<string | null> | null | undefined
      >
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Dictionary<any> {
      const newQuery: Dictionary<
        string | number | Array<string | null> | null | undefined
      > = {};
      Object.entries(query).forEach(([key, val]) => {
        if (typeof val !== "undefined" && val !== null && val !== "") {
          newQuery[key] = val;
        }
      });
      return newQuery;
    },
  },
  async created(): Promise<void> {
    const selectedCategoryId = this.selectedCategoryId
      ? Object.keys(this.selectedCategoryId as { [key: string]: boolean })[0]
      : undefined;
    if (this.$route.query.kategoria !== selectedCategoryId) {
      this.selectedCategoryId = this.$route.query.kategoria
        ? {
            [this.$route.query.kategoria as string]: true,
          }
        : null;
    }
    if (this.$route.query.nowosci) {
      this.isNew = true;
    }
    if (this.$route.query.wyprzedaz) {
      this.isSale = true;
    }
    if (this.$route.query.zestawy) {
      this.isSet = true;
    }
    if (this.$route.query.premium) {
      this.isPremium = true;
    }
    if (this.$route.query.sortujWg) {
      this.sortBy = this.$route.query.sortujWg as string;
    }
    if (this.$route.query.sortujTyp) {
      this.sortType = this.$route.query.sortujTyp as SortType;
    }
    if (this.$route.query.minCena) {
      this.minPrice = Number(this.$route.query.minCena as string);
    }
    if (this.$route.query.maxCena) {
      this.maxPrice = Number(this.$route.query.maxCena as string);
    }
    if (this.$route.query.szukaj) {
      this.search = this.$route.query.szukaj as string;
    }

    //
  },
  watch: {
    $route: {
      handler(): void {
        const selectedCategoryId = this.selectedCategoryId
          ? Object.keys(
              this.selectedCategoryId as { [key: string]: boolean }
            )[0]
          : undefined;
        if (selectedCategoryId !== this.$route.query.kategoria) {
          this.selectedCategoryId = this.$route.query.kategoria
            ? {
                [this.$route.query.kategoria as string]: true,
              }
            : null;
        }
        if (this.isNew !== !!this.$route.query.nowosci)
          this.isNew = !!this.$route.query.nowosci;
        if (this.isSale !== !!this.$route.query.wyprzedaz)
          this.isSale = !!this.$route.query.wyprzedaz;
        if (this.isSet !== !!this.$route.query.zestawy)
          this.isSet = !!this.$route.query.zestawy;
        if (this.isPremium !== !!this.$route.query.premium)
          this.isPremium = !!this.$route.query.premium;
        if (this.search !== this.$route.query.szukaj)
          this.search = this.$route.query.szukaj;
      },
    },
    search(): void {
      this.updateQuery();
    },
    maxPrice(): void {
      this.updateQuery();
    },
    minPrice(): void {
      this.updateQuery();
    },
    sortType(): void {
      this.updateQuery();
    },
    selectedCategoryId(): void {
      this.updateQuery();
    },
    isNew(): void {
      this.updateQuery();
    },
    isSale(): void {
      this.updateQuery();
    },
    isSet(): void {
      this.updateQuery();
    },
    isPremium(): void {
      this.updateQuery();
    },
    sortBy(): void {
      if (this.sortType !== SortType.ASC) {
        this.sortType = SortType.ASC;
        return;
      }
      this.updateQuery();
    },
  },
});
