
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon.vue";
import Vue from "vue";
import { ModuleNames } from "@/store";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { convertStringToUrl } from "@/helpers/url";
import {
  faHome,
  faCogs,
  faListUl,
  faStore,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

type AccountRoute = {
  title: string;
  icon: any;
  path: string | { name: string };
  subRoutes?: Array<AccountRoute>;
};

export default Vue.extend({
  name: "ClarityNavBar",
  components: { SvgIcon, FontAwesomeIcon },
  data: (): {
    isOpen: boolean;
    faUser: any;
    faSearch: any;
    search: string;
  } => ({
    isOpen: false,
    faUser,
    faSearch,
    search: "",
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, {
      isAuthenticated: "isAuthenticated",
      authUser: "authUser",
      isShopModerator: "isShopModerator",
    }),
    ...mapState({ logo: "logo", companyNameNav: "companyNameNav" }),
    ...mapGetters({ isOpenCart: "isOpenCart" }),
    ...mapState(ModuleNames.shop, { categoryBanners: "categoryBanners" }),
    ...mapGetters(ModuleNames.shop, { shopCategories: "shopCategories" }),
    ...mapGetters(ModuleNames.cart, { cartItemQuantity: "cartItemQuantity" }),
    searchWidth(): number {
      if (!this.search) return 84;
      const getTextWidth = (text: string, font: string): number => {
        // re-use canvas object for better performance
        const canvas =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          getTextWidth.canvas ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      };
      const width =
        getTextWidth(this.search, "normal 14px 'Plus Jakarta Sans'") + 42;
      const parentWidth =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$refs.searchInput?.parentElement?.offsetWidth || 400;

      if (width < 84) return 84;
      if (width > parentWidth) return parentWidth;
      return width;
    },

    accountRoutes(): Array<AccountRoute> {
      const routes = [
        {
          title: this.$t("navbar.account") as string,
          icon: faHome,
          path: { name: "account" },
        },
        {
          title: this.$t("navbar.orders") as string,
          icon: faListUl,
          path: { name: "account-my-shippings" },
        },
      ];
      if (this.isShopModerator) {
        routes.push({
          title: this.$t("shop") as string,
          icon: faStore,
          path: { name: "account-shop" },
        });
      }
      routes.push({
        title: this.$t("settings") as string,
        icon: faCogs,
        path: { name: "account-settings" },
      });
      return routes;
    },
  },
  watch: {
    $route(): void {
      this.closeDropdown();
      if (!this.$route.query.szukaj) this.search = "";
    },
  },
  methods: {
    ...mapActions(ModuleNames.auth, ["logout"]),
    ...mapMutations(["setIsLoading"]),
    ...mapActions(["openCart", "closeCart"]),
    toggleCart(): void {
      if (this.isOpenCart) {
        this.closeCart();
      } else {
        this.openCart();
      }
    },
    convertStringToUrl,
    async logoutUser(): Promise<void> {
      this.setIsLoading(true);
      try {
        await this.logout();
        // } catch (e: any) {
      } finally {
        this.setIsLoading(false);
      }
    },
    toggle(): void {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(): void {
      this.isOpen = false;
    },
  },
});
