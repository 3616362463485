import { render, staticRenderFns } from "./ProductsView.vue?vue&type=template&id=d8be1644&scoped=true&"
import script from "./ProductsView.vue?vue&type=script&lang=ts&"
export * from "./ProductsView.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsView.vue?vue&type=style&index=0&id=d8be1644&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8be1644",
  null
  
)

export default component.exports